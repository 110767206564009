









































































import { Component, Vue } from 'vue-property-decorator';

import { ArticleRepository } from '@/repositories/ArticleRepository';
import { ArticleItem } from '@/models/ArticleItem';

@Component
export default class P0913 extends Vue {
  showPageFlag = false;
  data = {} as ArticleItem;

  created() {
    this.onLoad();
  }

  onLoad() {
    if (!this.$route.params.id) {
      return;
    }
    this.fetchNews();
  }

  get ArticleRepository() {
    return new ArticleRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  async fetchNews() {
    const item = await this.ArticleRepository.select(this.$route.params.id);

    if (!item) {
      return this.$router.replace('/notfound');
    }
    this.data = item;
    this.showPageFlag = true;
  }

  shareSns(type: string): void {
    var url = encodeURIComponent(location.href);
    var title = encodeURIComponent(document.title);

    if (type === 'facebook') {
      window.open(
        '//www.facebook.com/sharer.php?src=bm&u=' + url + '&t=' + title,
        '',
        'width=650,height=600'
      );
    } else if (type === 'twitter') {
      window.open(
        'http://twitter.com/intent/tweet?text=' + title + '%0A' + url,
        '',
        'width=500,height=450'
      );
    } else if (type === 'line') {
      window.open('//line.me/R/msg/text/?' + title + '%0A' + url);
    }
  }
}
